import React from 'react'
import styled from 'styled-components';

function About() {
    return (<>
  {/* Start of about section    
  ============================================= */}
  <section
    id="saas_two_about"
    className="saas_two_about_section relative-position"
  >
    <div className="container">
      <div className="about_content_s2">
        <div className="row">   
          <div
            className="col-lg-6 col-md-12 wow fadeFromLeft"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="s2-about_shape position-absolute">
              <img src="images/banner-b-shape5.png" alt='' />
            </div>
            <div className="about_mockup">
              <img src="images/banner_art.png" alt='' />
            </div>            
          </div>       
          <div
            className="col-lg-6 col-md-12 wow fadeFromRight"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="s2-about_text_icon">
              <div className="s2-about_text saas2-headline pera-content">
                <S.Title>Step 1; <font style={{fontFamily: 'Poppins Light'}}>Starting in Vancouver, BC:</font></S.Title>
                <S.Content>
                In British Columbia where we are based, more than 150,000 children in British Columbia are living in impoverished households. We will begin this project by finding and helping families in need in Vancouver, BC with the goal of expanding to other cities and regions as the project grows.
                </S.Content>
                {/* <div className="about_btn">
                  <a href="#">
                    What is Amy's Adventure?
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          
        </div>
      </div>
      {/* /about first content */}
    </div>
  </section>
  {/* End of About  section    
  ============================================= */}
</>

    )
}

export default About

const S = {
  Title: styled.div`
    font-family: Font Le Chant;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 35px !important;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 45px !important;
    }
    @media (min-width: 1150px) {
      font-size: 50px !important;
    }
    @media (min-width: 1440px) {
      font-size: 55px !important;
    }
  `,
  Content: styled.div`
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 14px !important;
    font-family: Poppins Light;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
      font-size: 16px !important;
    }
    @media (min-width: 1024px) {
      font-size: 18px !important;
    }
    @media (min-width: 1440px) {
      font-size: 20px !important;
    }
  `
}

