import React from 'react'
import styled from "styled-components";

function Learn() {
    return (<>
  {/* Start of learn section    
  ============================================= */}
  <section
    id="saas_two_learn"
    className="saas_two_about_section relative-position"
    style={{background: '#fff'}}
  >
    <div className="container">
      <div className="about_content_s2">
        <div className="row">          
          <div
            className="col-lg-6 col-md-12 wow fadeFromLeft"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="s2-about_text_icon">
              <div className="s2-about_text saas2-headline pera-content">
                <S.Title><font style={{fontFamily: 'Font Le Chant'}}>Giving </font><font style={{fontFamily: 'Poppins Light'}}>$1000/day</font> <font style={{fontFamily: 'Font Le Chant'}}>to a child everyday</font></S.Title>
                <S.Content>
                  An estimated 356 million children worldwide live in extreme poverty.
                  11.6 million children — 16% of all kids in the US — were living in poverty in 2020. In Canada, 1.3 million children live in conditions of poverty (that’s 1 in 5). While we cannot eliminate poverty at this stage in our project, we are looking to contribute to the happiness of these children. 
                </S.Content>
                {/* <div className="banner_btn">
                  <a href="#">
                    Learn More
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 wow fadeFromRight"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="s2-about_img">
              <img src="images/banner-girl.png" alt='' />
            </div>
          </div>
        </div>
        <div className="about_content_center">
          <div className="row">
            <div
                className="col-lg-12 col-md-12 wow fadeFromUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="s2-about_text_icon">
                <div className="s2-about_text saas2-headline text-center">
                  <S.Title><font style={{fontFamily: 'Font Le Chant'}}>Goal:</font> <font style={{fontFamily: 'Poppins Light'}}>Raise 300 ETH</font></S.Title>
                  <S.Content>
                    Amy's Adventures Club is passionate about giving to children, which is why we have set a modest goal of giving $1,000 a day to underprivileged children. With your help, we will have the capabilities to bring happiness to children around the world. 
                  </S.Content>
                  <div className="about_coinup">
                    <img src="images/learn-coin.png" alt='' />
                  </div>
                  <div className="about_coindown">
                    <img src="images/learn-coin-show.png" alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /about first content */}
    </div>
  </section>
  {/* End of About  section    
  ============================================= */}
</>

    )
}

export default Learn

const S = {
  Title: styled.div`    
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 30px !important;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 45px !important;
    }
    @media (min-width: 1150px) {
      font-size: 50px !important;
    }
    @media (min-width: 1440px) {
      font-size: 55px !important;
    }
  `,
  Content: styled.div`
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 14px !important;
    font-family: Poppins Light;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
      font-size: 16px !important;
    }
    @media (min-width: 1024px) {
      font-size: 18px !important;
    }
    @media (min-width: 1440px) {
      font-size: 20px !important;
    }
  `
}
