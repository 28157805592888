import React, {useState, useContext} from "react";
import styled from 'styled-components';
import {AppContext} from '../component/contexts'

function Footer() {
  const {showMsg} = useContext(AppContext);
  const [email, setEmail] = useState('');
  const Submit = async() => {
    if(email == '') {
      showMsg("Please enter your email address", "error");
      return;
    }
    window.Email.send({ 
      Host: "smtp.gmail.com", 
      Username: "ronaldo888kgs@gmail.com", 
      Password: "twcgbawtuwxbqwcz", 
      To: 'ronaldo888kgs@gmail.com', 
      From: email, 
      Subject: "Customer question", 
      Body: "email : " + email + " <br/>" + 
      "message : Client asks contacting with you", 
    }).then(function (message) { 
      // enqueueSnackbar("mail sent successfully", { variant: 'success' });
    }); 
  }
  return (
    <>
      {/* Start of footer section
  ============================================= */}
      <footer
        id="saas_two_contact"
        className="saas_two_footer_section relative-position"
      >
        <div className="s2-newslatter_section relative-position" style={{background: '#fee600'}}>
          <div className="container">
            <div className="s2-newslatter_content relative-position" style={{background: '#fee600'}}>
              <div className="s2-newslatter_title  text-center saas2-headline pera-content">
                <S.Title>Subscribe for more info!</S.Title>
              </div>
              <div className="s2-newslatter-form  relative-position">
                <S.Input
                  className="email"
                  name="email"
                  type="email"
                  onChange={setEmail}
                  value={email}
                  placeholder="Enter your email address"
                />
                <div className="nws-button position-absolute text-capitalize">
                  <S.Button className="hover-btn" onClick={Submit}>
                    Submit
                  </S.Button>
                </div>
              </div>
            </div>
            <S.BanerImg className="newsletter_pattern_1">
              <img src="images/banner-nb.png" alt='' />
            </S.BanerImg>            
          </div>
        </div>
        <div className="footer_content text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="s2_footer_widget clearfix ul-li-block saas2-headline">
                  <S.Social>
                    <a href="https://www.facebook.com/mint" target="_blank">
                      <i className="fb-bg fab fa-facebook-f " style={{color: 'black'}} />
                    </a>
                    <a href="https://www.youtube.com/mint" target="_blank"><i className="dr-bg fab fa-youtube" style={{color: 'black'}}></i></a>
                    <img src="images/logo_img.png" alt='' />
                    <a href="https://twitter.com/mint" target="_blank">
                      <i className="tw-bg fab fa-twitter" style={{color: 'black'}} />
                    </a>
                    <a href="https://t.me/mint" target="_blank">
                      <i className="dr-bg fab fa-instagram" style={{color: 'black'}} />
                    </a>
                  </S.Social>
                </div>
              </div>
            </div>
          </div>
        </div>
        <S.Content className="s2-copyright text-center">
          Copyright @ Amy's Adventure Club
        </S.Content>
      </footer>
      
      {/* End of footer section 
  ============================================= */}
    </>
  );
}

export default Footer;

const S = {  
  BanerImg: styled.div`
    left: 10px !important;
    @media (min-width: 1100px) {
      left: 40px !important;
    }
    @media (min-width: 1280px) {
      left: 80px !important;
    }
  `,
  Button: styled.button`
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 14px !important;
    font-family: Poppins Light;
    text-align: center !important;
    height: 42px !important;    
    width: 70px !important;
    @media (min-width: 600px) {
      font-size: 14px !important;
      width: 100px !important;
    }
    @media (min-width: 768px) {
      height: 62px !important;
      text-align: left;
      font-size: 16px !important;
      width: 155px !important;
    }
    @media (min-width: 1024px) {
      font-size: 18px !important;
    }
    @media (min-width: 1440px) {
      font-size: 20px !important;
    }
  `,
  Input: styled.input`
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 13px !important;
    font-family: Poppins Light;
    text-align: left;
    height: 42px !important;
    padding-left: 10px !important;
    @media (min-width: 768px) {
      height: 62px !important;
      padding-left: 30px !important;
      font-size: 16px !important;
    }
    @media (min-width: 1024px) {
      font-size: 18px !important;
      width: 90%;
      margin-left: 10%;
    }
    @media (min-width: 1440px) {
      font-size: 20px !important;
      width: 100%;
      margin-left: 0;
    }
  `,
  Title: styled.div`
    font-family: Font Le Chant;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 35px !important;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 45px !important;
    }
    @media (min-width: 1150px) {
      font-size: 50px !important;
    }
    @media (min-width: 1440px) {
      font-size: 55px !important;
    }
  `,
  Social: styled.div`
    justify-content: center;
    gap: 20px;
    display: flex;
    color: white;

    a {
      margin-top: 25px;
    }
  `,
  Content: styled.div`
    color: #black;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 14px !important;
    font-family: Poppins Light;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 16px !important;
    }
    @media (min-width: 1024px) {
      font-size: 18px !important;
    }
    @media (min-width: 1440px) {
      font-size: 20px !important;
    }
  `
}