import React,  {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Carousel from 'react-multi-carousel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import Image from '../UI/Image';
import Incrementer from '../UI/Incrementer';
import ContainedButton from '../UI/Buttons/ContainedButton';
import { AppContext } from '../contexts';
import { PUBLIC_SALE_DATE, TOKEN_SYMBOL, nftContractAddress, CHAIN_ID } from '../constant'
import FlipDateCounter from './FlipDateCounter'
import {nftMint, getTotalMinted, isPresaleUser, getMintedAmount} from '../../services/mint'
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles((theme, isConnected) => ({
  root: {
    padding: '40px 5px',
    background: '#fff !important',

    [theme.breakpoints.up('sm')]: {
      padding: '60px 10px'
    }
  },
  largeSvgIcon: {
    '& svg': {
      fontSize: 40
    }
  },
  mintDig: {
    borderRadius: 20,
    background: '#fafafa',
    padding: 0,
    margin: 'auto',
    marginTop: '30px',

    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
      maxWidth: 600,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 960 //960
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 1200,
      maxWidth: 1300
    },
    [theme.breakpoints.up(1440)]: {      
      minWidth: 1430,
      maxWidth: 1440,
      borderRadius: 30
    },
    [theme.breakpoints.up(1660)]: {
      minWidth: 1440,
      maxWidth: 1440
    }
  },
  mintTitle: {
    fontFamily: 'Font Le Chant',
    color: 'black',
    textAlign: 'center',
    flexWrap: 'wrap',
    fontSize: '35px',
    letterSpacing: '0.05em',
    margin: '20px 5px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
      letterSpacing: '0.07em',
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '45px',
      marginTop: 10
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '50px',
      letterSpacing: '0.1em',
    },
    [theme.breakpoints.up(1440)]: {
      marginTop: 30,
      marginBottom: 15,
      fontSize: '55px'
    }
  },
  mintDesc: {
    fontFamily: 'Poppins Light',
    color: 'black',
    textAlign: 'center',
    fontSize: 13,
    marginTop: 5,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      marginTop: 8
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      marginTop: 8
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: 20,
      marginTop: 10
    }
  },
  mintMaxDesc: {
    fontFamily: 'Poppins Light',
    width: '100%',
    color: 'black',
    textAlign: 'center',
    fontSize: 14,
    margin: '20px 10px 0px 10px',

    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      textAlign: 'center',
      marginTop: 20
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 17,
      textAlign: 'center',
      marginTop: 10
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      textAlign: 'center',
      marginTop: 20
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: 20,
      textAlign: 'center',
      marginTop: 20
    }
  },
  mintPrice: {
    fontFamily: 'Poppins Light',
    color: '#ffc107',    
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      fontWeight: 600
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
      fontWeight: 600
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: 25,
      fontWeight: 600
    }
  },
  mintBottomDiv: {
    width: '100%',
    display:'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      marginTop: 15
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 10
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 15,
      marginBottom: 30
    },
    [theme.breakpoints.up(1440)]: {
      marginTop: 20,
      marginBottom: 40
    }
  },
  mintedCount: {
    display:'flex',
    width: '100%',
    marginBottom: 5,    
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 15
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 10
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 15
    },
    [theme.breakpoints.up(1440)]: {
      marginBottom: 30
    }
  },
  mintCounter: {     
    backgroundColor: "#417585", 
    color: 'black',  
    textAlign: 'center', 
    verticalAlign: 'center',
    width: 40, 
    height: 50,      
    margin: 5, 
    borderRadius: 5,
    
    [theme.breakpoints.up('sm')]: {
      width: 50, 
      height: 60,      
      margin: 5, 
      borderRadius: 5,
    },
    [theme.breakpoints.up('lg')]: {
      width: 50, 
      height: 60,      
      margin: 5, 
      borderRadius: 5,
    },
    [theme.breakpoints.up(1440)]: {
      width: 60, 
      height: 60,      
      margin: 5, 
      borderRadius: 5,
    }
  },
  mintCounterContainer: {
    display: 'inline-block',
    [theme.breakpoints.up('lg')]: {
      marginBottom: 10
    },
    [theme.breakpoints.up(1440)]: {
      marginBottom: 20
    }
  },
  mintCounterDesc: {
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    fontWeight: 300,

    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      fontWeight: 400
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
      fontWeight: 500
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      fontWeight: 600
    }
  },
  mintNumberContainer: {
    display: 'flex', 
    flexWrap: 'wrap', 
    flexDirection: 'row',
    alignItems: 'center', 
    height: 'inherit', 
    justifyContent: 'center'
  },
  mintNumber :{
    color: 'black',
    fontSize: 20,
      fontWeight: 400,

    [theme.breakpoints.up('lg')]: {
      fontSize: 25,
      fontWeight: 600
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: 30,
      fontWeight: 600
    }
  },
  mintDivider: {
    borderTop: '1px solid',
    borderColor: 'black',
    position: 'absolute', 
    width: 20,
    [theme.breakpoints.up('lg')]: {
      borderTop: '2px solid',
      width: 40,
      borderColor: '#417585'
    }
  },
  image: {
    borderRadius: 20,
    padding: '4px',
    width: 355,
    height: 355,
    [theme.breakpoints.up(414)]: {
      width: 394,
      height: 394
    },
    [theme.breakpoints.up('sm')]: {
      width: 550,
      height: 550
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      width: 400,
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      width: 500,
      height: 500
    },
    [theme.breakpoints.up(1440)]: {
      width: 600,
      height: 600,
      borderRadius: 32
    }
  },
  cover: {
    margin: 0,
    width: 355,
    height: 355,
    paddingBottom: 10,
    [theme.breakpoints.up(414)]: {
      width: 394,
      height: 394
    },
    [theme.breakpoints.up('sm')]: {
      margin: '20px 0 20px 0',
      width: 550,
      height: 550
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      width: 400,
      height: 400,
      paddingBottom: 0
    },
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      width: 500,
      height: 500
    },
    [theme.breakpoints.up(1440)]: {
      width: 600,
      height: 600
    }
  },
  carouselContainer: {
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    }
  },
  mintCountController: {
    width: '160px !important',
    height: '47px',

    [theme.breakpoints.up('sm')]: {
      width: '200px !important',
      height: 55
    },
    [theme.breakpoints.up('lg')]: {
      width: '260px !important',
      height: 60
    }
  },
  mintButtonSmall:{
    color: 'black',
    borderRadius: '100px',
    border: 0,
    fontSize: isConnected? 24 : 13,
    fontFamily: 'Poppins Light',
    fontWeight: 500,
    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '160px !important',
    height: '47px',
    backgroundColor: '#fee600',

    [theme.breakpoints.up('sm')]: {
      width: '200px !important',
      height: 55,
      fontSize: isConnected? 23 : 16,
      fontWeight: 600
    },
    [theme.breakpoints.up('lg')]: {
      width: '260px !important',
      height: 60,
      fontSize: isConnected? 26 : 20,
      fontWeight: 600
    },
    [theme.breakpoints.up(3000)]: {
      width: '260px !important',
      height: 60,
      fontSize: isConnected? 30 : 22,
      fontWeight: 600 
    }
  },
  mintButton: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    color: 'black',
    backgroundColor: '#000',
    alignItems: 'center',
    border: '2px solid',
    borderColor: '#000',
    height: 35,
    width: 35,
    borderRadius: 35,
    fontSize: 14,
    fontFamily: 'Play,sans-serif',
    fontWeight: 500,
    cursor: 'pointer !important',

    [theme.breakpoints.up('lg')]: {
      height: 40,
      width: 40,
      borderRadius: 40,
      fontSize: 16,
      fontWeight: 600
    },
    [theme.breakpoints.up(1440)]: {
      height: 50,
      width: 50,
      borderRadius: 50,
      fontSize: 20,
      fontWeight: 600
    }
  },
  buttonDiv: {
    display:'flex',
    flexWrap: 'wrap', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    margin: '0 10px',
    gap: 20,

    [theme.breakpoints.up('sm')]: {
      display:'flex'
    }
  },
  carouselItem: {
    paddingRight: '0px'
  }
}));

const Mint2 = props => {
  const { connected, showMsg, loadingStatus, setLoadingStatus, web3, address, saleState, chain_id} = useContext(AppContext)
  const { className } = props;
  const classes = useStyles(connected);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [inputValues, setInputValues] = useState(1);
  const [ days, setDays ] = useState(0);
  const [ hours, setHours ] = useState(0);
  const [ minutes, setMinutes ] = useState(0);
  const [mintDate, setMintDate] = useState();
  const [maxMint, setMaxMint] = useState(2);
  const [price, setPrice] = useState(0.10);
  const [presaleType, setPresaleType] = useState('nouser');
  const [mintedAmount, setMintedAmount] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [possibleAmount, setPossibleAmount] = useState(1);
  const [saleStart, setSaleStart] = useState(0);
  const [saleTxt, setSaleTxt] = useState('Public sale available on ');

  useEffect(()=>{
    const w_today = new Date();
    const w_mintDate = new Date(PUBLIC_SALE_DATE);
    setMintDate(w_mintDate.toDateString())    
    let miliseconds = (w_mintDate - w_today);
    if(miliseconds <= 0) {
      setSaleStart(2);
    } else {
      setSaleStart(1);
      let w_days = Math.floor(miliseconds / 86400000);
      let w_hours = Math.floor((miliseconds % 86400000) / 3600000);
      let w_mins = Math.floor(((miliseconds % 86400000) % 3600000) / 60000);
      setDays(w_days);
      setHours(w_hours);
      setMinutes(w_mins);
    }
  },[])

  const getTotalSupply = async() => {
    if(web3 == undefined || address == '') return;
    let _cnt = await getTotalMinted(web3);
    setTotalSupply(_cnt);
  }
  const getmintedAmt = async() => {      
    if(web3 == undefined || address == '') return;
    let _cnt = await getMintedAmount(web3, address, saleState);
    setMintedAmount(_cnt);
    let _possibleAmt = maxMint - _cnt;
    setPossibleAmount(_possibleAmt)
  }
  useEffect(()=>{   
    getTotalSupply();
    getmintedAmt();
  },[address, saleState])

  useEffect(() => {
    if(address == '' || web3 == undefined) return;
    const init = async () => {      
      let type = await isPresaleUser(web3, address);
      setPresaleType(type);
    }
    init();
  },[address])

  useEffect(() => {
    if(saleState == 'presale') {
      setSaleTxt('PRE-SALE IS ACTIVE');
      let _amount = 2;
      setMaxMint(_amount);
      if(presaleType == 'whitelist') {
        setPrice(0.08);
      }
    } else if(saleState == 'public') {
      setSaleTxt('PUBLIC-SALE IS ACTIVE');
      let _amount = 3;
      setMaxMint(_amount);
    } else if(saleState == 'private') {
      setSaleTxt('PRIVATE-SALE IS ACTIVE');
      let _amount = 100;
      setMaxMint(_amount);
    } else {
      if(saleStart == 2) {
        setSaleTxt("SALE HAS BEEN CLOSED")
      } else if(saleStart == 1) {
        let options = {  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit',  hour12: true, timeZone: 'UTC', timeZoneName : 'short'};
        let _mintDate = 'Public sale available on ' + new Date(Date.UTC(2022, 4, 1, 20, 0, 0)).toLocaleTimeString('en-us', options);
        setSaleTxt(_mintDate);
      }
    }
  }, [saleState, presaleType, saleStart])

  useEffect(()=>{    
    setPossibleAmount(maxMint - mintedAmount);
  },[maxMint, mintedAmount])

  useEffect(() => {
    if(inputValues < 1)
      setInputValues(1)
    else if(inputValues > maxMint)
      setInputValues(maxMint)
  },[inputValues])

  const mint = async () => {
    if(!connected) {
      showMsg("Please connect to Metamask", "error");
      return;
    } 
    if(chain_id != CHAIN_ID) {
      showMsg("Please switch to Ethereum Mainnet", "error");
      return;
    }
    if(totalSupply >= 88888) {
      showMsg("Minting would exceed the total supply", "error");
      return;
    }
    if(inputValues < 1) {
      showMsg("Please select amount", "error");
      return;
    }
    setLoadingStatus(true);
    if(inputValues > possibleAmount) {
      showMsg("You have already minted " + mintedAmount + " NFTs.", "error");
      setLoadingStatus(false)
      return;
    }
    const ret = await nftMint(web3, address, inputValues, price, presaleType, mintedAmount);
    if(ret.type=='success') {
      if(mintedAmount == 0) {
        await window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
                type: "ERC20",
                options: {
                    address: nftContractAddress,
                    symbol: TOKEN_SYMBOL,
                    decimals: 0,
                    image: "",
                },
            },
        });        
      }
      await getmintedAmt();
      getTotalSupply();
    }    
    showMsg(ret.msg, ret.type)
    setLoadingStatus(false)
  }

  const images3= [
    "images/collection3/1.jpg",
    "images/collection3/2.jpg",
    "images/collection3/3.jpg",
    "images/collection3/4.jpg",
    "images/collection3/5.jpg",
    "images/collection3/6.jpg"
  ]

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.mintDig}                                    
        data-aos="fade-in"
        data-aos-delay="300"
        data-aos-duration="1300">
        <Grid container direction={isMd ? 'row' : 'column-reverse'}>   
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            md={5}
            justifyContent={'center'}
            className={classes.carouselContainer}
          >
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={500}
              keyBoardControl={false}
              customTransition="all .5"
              transitionDuration={1000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["desktop","tablet", "mobile"]}
              deviceType={'desktop'}
              dotListClass="custom-dot-list-style"
              itemClass={classes.carouselItem}
              className={classes.cover}
              partialVisible={true}
            >
              {images3.map((image,index) => {
                return  <Image
                  src={image}
                  key={index}
                  alt={index + '_img'}
                  lazy={false}
                  className={classes.image}
                />
              })}
            </Carousel>
          </Grid>         
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            md={7}
            data-aos={'fade-up'}
          >  
            <span className={classes.mintTitle}>
              Amy’s Adventures
            </span>
            <div style={{textAlign: 'center'}}>
              <span className={classes.mintDesc}>
                {saleTxt}
                <br />
              </span> 
              <div style={{width: '100%'}}>
                {saleStart > 0 && 
                  <FlipDateCounter day={days} hour={hours} min={minutes} />
                }              
              </div>
            </div> 
            <span className={classes.mintMaxDesc}>
              Enter how many Adoration you'd like to mint.<br />
              (Maximum 50)
            </span>
            <div className={classes.mintedCount}>
              <span className={classes.mintDesc} style={{color: '#8ACBDE'}}>
              {totalSupply}&nbsp;&nbsp;of&nbsp;&nbsp;88888&nbsp;&nbsp;Minted
              </span>              
            </div>
            
            <div className={classes.buttonDiv}>
              <div style={{display:'flex',flexWrap: 'wrap', flexDirection: 'column', alignItems: 'center'}}>
                <Incrementer
                  label=""
                  min="0"
                  max={possibleAmount}
                  onChange={e=>setInputValues(e.target.value)}
                  value={inputValues}
                  className={classes.mintCountController}
                  />
              </div>
              <div style={{display:'flex',flexWrap: 'wrap',flexDirection: 'column', alignItems: 'center'}}>
                <ContainedButton
                  loading={loadingStatus}
                  // disable={!isSale}
                  onClick={() => mint()}
                    className={[classes.mintButtonSmall].join(" ")}
                  >
                    Mint Now                   
                </ContainedButton>
              </div>
            </div>
            <div className={classes.mintBottomDiv}>
              <span className={classes.mintPrice}>
                  Ξ {Math.round(inputValues * price * 100) / 100} + GAS
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Mint2.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Mint2;
