import React from "react";
import About from "./About";
import Card from "./Card";
import Home from './Home';
import Learn from "./Learn";
import Load from "./Loadmap";
import Setting from "./Setting"


function Main() {    
  return (
      <div>
        <Home id="home" />
        <Card />
        <Load />
        <Learn />
        <About />
        {/* <Setting id="saas_two_setting" /> */}
      </div>
  );
}

export default Main;
