import React from "react";
import styled from 'styled-components'
import {Link} from 'react-router-dom';
function Home() {

  return (
    <>
      {/* Start of Banner section    
  ============================================= */}
      <S.Root
        id="saas_two_banner"
        className="saas_two_banner_section relative-position"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <S.Container className="s2-banner_area relative-position">
                <div className="s2-banner_content saas2-headline pera-content">
                  <div
                    className="wow fadeFromLeft"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    style={{maxWidth: 500}}
                  >
                    <S.Des><S.Des1>Helping Underprileged Children</S.Des1> <S.Des2>with</S.Des2> <S.Des1>NFTs Collectibles</S.Des1></S.Des>
                    <S.DesSmall>
                      Join Amy's Adventures Club and Help Children in Need
                    </S.DesSmall>
                    <div className="banner_btn" style={{marginTop: '15px'}}>
                      <Link to="/mint">
                        <S.MintText>
                          Mint Now
                        </S.MintText>
                      </Link>
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center"}}>
                  <S.BG className="s2-banner_shape6 position-absolute">
                    <img src="images/banner-b-shape7.png" alt='' />
                  </S.BG>
                  <S.ImgDiv className="banner_mockup">
                    <img src="images/banner_art.png" alt='' />
                  </S.ImgDiv>                  
                </div>
              </S.Container>
            </div>
          </div>
        </div>
        
      </S.Root>
      {/* End of Banner section
  ============================================= */}
    </>
  );
}

export default Home;

const S = {
  MintText: styled.p`
    color: red;
    font-size: 20px !important;
    font-family: Poppins Medium !important;
    margin-top: 2px;

    &:hover,&:focus,&:active {
      color: white;
    }
  `,
  ImgDiv: styled.div`
    max-width: 400px !important;
    right: 20px !important;
    top: -60px !important;
    @media (min-width: 1150px) {
      max-width: 500px !important;
      right: 30px !important;
    }
    @media (min-width: 1280px) {
      max-width: 550px !important;
      right: 40px !important;
    }
    @media (min-width: 1440px) {
      max-width: 630px !important;
      right: 50px !important;
    }
  `,
  BG: styled.div`
    top: 23rem !important;
    right: 0px !important;
    width: 100% !important;
    @media (min-width: 768px) {
      top: 0px !important;
      right: -10px !important;
      width: 55% !important;
    }
    @media (min-width: 1024px) {
      top: -100px !important;
      right: 0px !important;
      width: 50% !important;
    }
  `,
  Container: styled.div`
    display: block;
    @media (min-width: 768px) {
      justify-content: space-around;
      display: flex;
    }
    @media (min-width: 1024px) {
      display: block;
    }
  `,
  Des: styled.h1`
    color: #000 !important;
    font-size: 35px !important;
    max-width: 400px;
    text-align: center !important;
    @media (min-width: 768px) {
      margin-top: 70px !important;
      text-align: left !important;
    }
  `,
  DesSmall: styled.p`
    color: #7f7e7e;
    font-weight: 600;
    font-size: 16px !important;
    padding-bottom: 5px !important;
    @media (min-width: 1024px) {
      font-size: 18px !important;
    }
  `,
  Des1: styled.span`
    font-family: Font Le Chant;
    color: #000;
    font-weight: 600;
    font-size: 35px !important;
    @media (min-width: 768px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 45px !important;
    }
    @media (min-width: 1150px) {
      font-size: 50px !important;
    }
    @media (min-width: 1440px) {
      font-size: 55px !important;
    }
  `,
  Des2: styled.span`
    font-family: Poppins Light;
    color: #000;
    font-weight: 600;
    font-size: 35px !important;
    @media (min-width: 768px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 45px !important;
    }
    @media (min-width: 1150px) {
      font-size: 50px !important;
    }
    @media (min-width: 1440px) {
      font-size: 55px !important;
    }
  `,
  Root: styled.section`
    padding-top: 80px !important;
    @media (min-width: 768px) {
      padding-top: 100px !important;
    }
    @media (min-width: 1150px) {
      padding-bottom: 350px !important;
    }
  `
}
