export const API_BASE_URL = 'http://localhost:3000';
export const discordUrl = "https://discord.gg/AmHHaHaE7P";
export const PUBLIC_SALE_DATE = '2022-05-01';
export const CHAIN_ID = 4;
export const ADMIN_ADDRESS = '0xde7a8daD8754d97B0Cb69fC6546Ed499750db46d';//"0x4F6B2D2E3C5730511Ec2709606F7283c6629b626";
export const TOKEN_SYMBOL = "ARN";
export const nftContractAddress = ['0x1109048F6cE29e2C1EfEE26D27daa34C557e2146','0x1109048F6cE29e2C1EfEE26D27daa34C557e2146','0x7154FA2791b1ff4Bf956a998BEb97b7daD345613'];
export const friendPrice = 0.05;
export const nft_count = 6;
export const CID_26 = "QmVYz2NjzMyJvq5pi1HvFwzXqG6WNqgfPHdAMMQGgz3xbK";
export const IPFS_URL = "https://ipfs.io/ipfs/";