import React, {useState, useContext, useEffect} from "react";
import styled from 'styled-components'
import {AppContext} from './contexts'
import {getData,getTemplate} from '../services/collection'
import CardTemplate from './CardTemplate'
import {Link} from 'react-router-dom';

function Collections() {
  const {web3, address, showMsg} = useContext(AppContext);
  const [nfts, setNFTs] = useState();
  const [nfts1, setNFTs1] = useState();
  const [nfts2, setNFTs2] = useState();

  useEffect(() => {
    if(web3 ==  undefined) {
      let data = getTemplate(0);
      setNFTs(data);
      let data1 = getTemplate(1);
      setNFTs1(data1);
  
      let data2 = getTemplate(2);
      setNFTs2(data2);
    } else {
      initData();
    }
    
  }, [web3])

  const initData = async () => {
    let data = await getData(web3, 0, 0);
    if(data == undefined || data == null || data.length == 0) return;
    setNFTs(data);

    let data1 = await getData(web3, 0, 1);
    if(data1 == undefined || data1 == null || data1.length == 0) return;
    setNFTs1(data1);

    let data2 = await getData(web3, 0, 2);
    if(data2 == undefined || data2 == null || data2.length == 0) return;
    setNFTs2(data2);
  }

  return (
    <>
      {/* Start of service  section    
  ============================================= */}
      <S.Root className="saas_two_banner_section relative-position">
        <div className="container">
          <S.HeadDiv className="row">
            <div className="col-md-6">
              <S.Container className="s2-banner_area relative-position" style={{flexDirection: 'column-reverse'}}>
                <div style={{ textAlign: "center"}}>
                  <S.BG className="s2-banner_shape6 position-absolute">
                    <img src="images/banner-b-shape8.png" alt='' />
                  </S.BG>
                  <S.ImgDiv className="banner_mockup">
                    <img src="images/banner_art.png" alt='' />
                  </S.ImgDiv>                  
                </div>
              </S.Container>
            </div>
            <div className="col-md-6">
                <S.DesDiv className="s2-banner_content saas2-headline pera-content">
                  <div
                    className="wow fadeFromRight"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    style={{maxWidth: 500}}
                  >
                    <S.Des><S.Des1>Amy's Adventures Club</S.Des1></S.Des>
                    <S.DesSmall>
                      Currently, there are 3 collections available:<br/>
                      1.	<font style={{fontFamily: 'Poppins Medium'}}>26 Emotions</font> – Reserved for True Believers and Investors of Amy’s Adventures Club.<br/>
                      2.	<font style={{fontFamily: 'Poppins Medium'}}>Amy’s Friends</font> – Reserved for Amy’s Adventures Club Members. See About Us on How to Become a member. <br/>
                      3.	<font style={{fontFamily: 'Poppins Medium'}}>Amy’s Adventures</font> – Public Sale for everyone who wants to contribute and be part of Amy’s Adventures Club. Buyers will receive merchandise with NFT purchase.  
                    </S.DesSmall>
                  </div>
                </S.DesDiv>
            </div>
          </S.HeadDiv>     
          {/* creator's nfts */}    
          <S.CollectionDiv1>
            <S.Title1><S.Des1>26 Emotions</S.Des1></S.Title1>
            <S.DesSmall1>
              26 Emotions - Throughout life, our feelings influence the choices that we make. Even though these emotions determine how we behave, we have not always expressed them in a healthy way. This collection was inspired to promote self awareness and mental wellness. 
            </S.DesSmall1>
            <S.MintButton>
              <Link to="/mint">
                <S.MintText>
                  Mint Now
                </S.MintText>
              </Link>
            </S.MintButton>
          </S.CollectionDiv1>
          <S.Collection1>
            <S.ColName>Adoration</S.ColName>
            <S.CardsContainer>
              {nfts&& nfts.map(Item => {             
                return (
                  <CardTemplate
                    key={`${Item?.name}`}
                    name={Item?.name}
                    description={Item?.description}
                    image={Item?.image}>
                  </CardTemplate>
                )
              })}
            </S.CardsContainer>
          </S.Collection1>
          {/* friend's nfts */}    
          <S.CollectionDiv1>
            <div>
              <S.Title1><S.Des1>Amy’s Friends</S.Des1></S.Title1>
              <S.DesSmall1>
                Amy’s Friends – Everything is meaningful with friends. We laugh a little harder, cry a little less, and smile a lot more. No matter how we are feeling right now, this collection was created to remind us that even if we drift apart, our memories will be forever.
              </S.DesSmall1>
            </div>
            <S.MintButton1>
              <Link to="/mint1">
                <S.MintText>
                  Mint Now
                </S.MintText>
              </Link>
            </S.MintButton1>
          </S.CollectionDiv1>
          <S.Collection2>
            <S.ColName>Adoration</S.ColName>
            <S.CardsContainer>
              {nfts1&& nfts1.map(Item => {             
                return (
                  <CardTemplate
                    key={`${Item?.name}`}
                    name={Item?.name}
                    description={Item?.description}
                    image={Item?.image}>
                  </CardTemplate>
                )
              })}
            </S.CardsContainer>
          </S.Collection2>
          {/* public nfts */}    
          <S.CollectionDiv1>
            <div>
              <S.Title1><S.Des1>Amy’s Adventures</S.Des1></S.Title1>
              <S.DesSmall1>
                Amy’s Adventures – “Our deepest fear is not that we are inadequate. Our deepest fear is that we are powerful beyond measure. We are all meant to shine, as children do.” In this moment, whether you’re hiding in the darkness or standing in the light, this collection was created to ignite your full potential. 
              </S.DesSmall1>
            </div>
            <S.MintButton1>
              <Link to="/mint2">
                <S.MintText>
                  Mint Now
                </S.MintText>
              </Link>
            </S.MintButton1>
          </S.CollectionDiv1>
          <S.Collection3>
            <S.ColName>Adoration</S.ColName>
            <S.CardsContainer>
              {nfts2&& nfts2.map(Item => {             
                return (
                  <CardTemplate
                    key={`${Item?.name}`}
                    name={Item?.name}
                    description={Item?.description}
                    image={Item?.image}>
                  </CardTemplate>
                )
              })}
            </S.CardsContainer>
          </S.Collection3>
          <S.HeadDiv className="row">
            <div className="col-md-6">
              <S.Container className="s2-banner_area relative-position" style={{flexDirection: 'column-reverse'}}>
                <div style={{ textAlign: "center"}}>
                  <S.BG1 className="s2-banner_shape6 position-absolute">
                    <img src="images/banner-b-shape5.png" alt='' />
                  </S.BG1>
                  <S.ImgDiv1 className="banner_mockup">
                    <img src="images/banner_art.png" alt='' />
                  </S.ImgDiv1>                  
                </div>
              </S.Container>
            </div>
            <div className="col-md-6">
                <S.DesDiv1 className="s2-banner_content saas2-headline pera-content">
                  <div
                    className="wow fadeFromRight"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    style={{maxWidth: 500}}
                  >
                    <S.Des><S.Des1>Starting in Vancouver, BC</S.Des1></S.Des>
                    <S.DesSmall>
                      In British Columbia where we are based, more than 150,000 children in British Columbia are living in impoverished households. We will begin this project by finding and helping families in need in Vancouver, BC with the goal of expanding to other cities and regions as the project grows.
                    </S.DesSmall>
                  </div>
                </S.DesDiv1>
            </div>
          </S.HeadDiv>    
        </div>
      </S.Root>
      {/* End of service section    
  ============================================= */}
    </>
  );
}

export default Collections;

export const S = {
  HeadDiv: styled.div`
    flex-direction: column-reverse;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  `,
  Collection1:styled.div`
    background: rgb(218, 241, 247);
    width: 100%;
    margin: auto;
    padding: 2rem 0;
    text-align: center;
    border-radius: 30px;
    margin-bottom: 4rem;
    @media (min-width: 768px) {
      width: 95%;
      padding: 1rem;
      margin-bottom: 6rem;
    }
    @media (min-width: 1024px) {
      width: 98%;
      padding: 0.5rem;
      margin-bottom: 7rem;
    }
    @media (min-width: 1150px) {
      width: 88%;
      padding: 2rem;
    }
  `,
  Collection2:styled.div`
    background: rgb(255, 252, 217);
    width: 100%;
    margin: auto;
    padding: 2rem 0;
    text-align: center;
    border-radius: 30px;
    margin-bottom: 4rem;
    @media (min-width: 768px) {
      width: 95%;
      padding: 1rem;
      margin-bottom: 6rem;
    }
    @media (min-width: 1024px) {
      width: 98%;
      padding: 0.5rem;
      margin-bottom: 7rem;
    }
    @media (min-width: 1150px) {
      width: 88%;
      padding: 2rem;
    }
  `,
  Collection3:styled.div`
    background: rgb(255, 217, 242);
    width: 100%;
    margin: auto;
    padding: 2rem 0;
    text-align: center;
    border-radius: 30px;
    margin-bottom: 2rem;
    @media (min-width: 768px) {
      width: 95%;
      padding: 1rem;
    }
    @media (min-width: 1024px) {
      width: 98%;
      padding: 0.5rem;
    }
    @media (min-width: 1150px) {
      width: 88%;
      padding: 2rem;
    }
  `,
  ColName: styled.span`
    display: flex;
    justify-content: center;
    width: auto;
    font-weight: 600;
    font-size: 35px;
    line-height: 16px;
    color: black;
    font-family: Font Le Chant;
    margin-top: 2rem;
    margin-bottom: 3rem;
    @media (min-width: 768px) {
      font-size: 35px;
    }
  `,
  CollectionDiv1: styled.div`
    width: 100%;
    margin-bottom: 2rem;
    justify-content: space-around;
    display: block;
    @media (min-width: 768px) {
      display: flex;
    }
  `,
  Container: styled.div`
    display: block;
    @media (min-width: 768px) {
      justify-content: space-between;
      display: flex;
    }
  `,
  DesDiv: styled.div`
    margin: 6rem 0rem 0rem !important;
    float: right;
    max-width: 100%;
    @media (min-width: 768px) {
      max-width: 300px !important;
      margin: 50px 0px !important;
    }
    @media (min-width: 1024px) {
      max-width: 450px !important;
      margin: 50px 0px !important;
    }
    @media (min-width: 1280px) {
      max-width: 550px !important;
      margin: 50px 0px !important;
    }
  `,
  Root: styled.section`
    padding: 0px !important;
  `,
  MintButton: styled.div`
    justify-content: center;
    display: flex;
  `,
  MintButton1: styled.div`    
    justify-content: center;
    display: flex;
    @media (min-width: 768px) {
      margin-top: 3rem;
    }
  `,
  MintText: styled.p`
    color: red;
    font-size: 20px !important;
    font-family: Poppins Medium !important;
    margin-top: 10px;
    background: #fee600 !important;
    width: 200px;
    height: 40px;
    border-radius: 20px;
    padding-top: 4px;
    text-align: center;
    &:hover,&:focus,&:active {
      color: blue;
    }
  `,
  ImgDiv: styled.div`
    max-width: 210px !important;
    left: 20px !important;
    margin-top: 30px !important;
    @media (min-width: 768px) {
      max-width: 240px !important;
      left: 40px !important;
      position: relative !important;
      top: -10px !important;
      margin-top: 100px !important;
    }
    @media (min-width: 1024px) {
      max-width: 260px !important;
      left: 150px !important;
      position: relative !important;
      top: -10px !important;
    }
    @media (min-width: 1150px) {
      max-width: 310px !important;
      left: 190px !important;
    }
    @media (min-width: 1280px) {
      max-width: 310px !important;
      left: 210px !important;
    }
    @media (min-width: 1350px) {
      max-width: 330px !important;
      left: 220px !important;
    }
  `,
  BG: styled.div`
    top: 3rem !important;
    left: 0px !important;
    width: 100% !important;
    @media (min-width: 768px) {
      top: 120px !important;
      margin-top: 20px;
    }
    @media (min-width: 1024px) {
      left: 0px !important;
    }
  `,
  ImgDiv1: styled.div`
  max-width: 250px !important;
  left: 20px !important;
  margin-top: 30px !important;
  @media (min-width: 768px) {
    max-width: 350px !important;
    left: 30px !important;
    position: relative !important;
    top: -10px !important;
    margin-top: 100px !important;
  }
  @media (min-width: 1024px) {
    max-width: 400px !important;
    left: 70px !important;
    position: relative !important;
    top: -10px !important;
  }
  @media (min-width: 1150px) {
    max-width: 310px !important;
    left: 190px !important;
  }
  @media (min-width: 1280px) {
    max-width: 310px !important;
    left: 210px !important;
  }
  @media (min-width: 1350px) {
    max-width: 330px !important;
    left: 220px !important;
  }
`,
  BG1: styled.div`
  margin: auto;
  width: 80% !important;
  top: -20px !important;
  left: 10%;
  @media (min-width: 768px) {
    width: 100% !important;
    top: 80px !important;
    left: 10%;
  }
  @media (min-width: 1024px) {
    width: 90% !important;
    max-width: 400px;
    left: 15% !important;
  }
  @media (min-width: 1150px) {
    left: 30% !important;
  }
`,
DesDiv1: styled.div`
  float: right;
  max-width: 100%;
  @media (min-width: 768px) {
    max-width: 300px !important;
  }
  @media (min-width: 1024px) {
    max-width: 450px !important;
  }
  @media (min-width: 1280px) {
    max-width: 550px !important;
  }
  `,
  Des: styled.h1`
    color: #000 !important;
    font-size: 35px !important;
    text-align: center !important;
    font-family: Poppins Light;
    @media (min-width: 768px) {
      margin-top: 70px !important;
      text-align: left !important;
    }
  `,
  DesSmall: styled.p`
    color: #7f7e7e;
    font-weight: 600;
    font-size: 14px !important;
    padding-bottom: 5px !important;
    font-family: Poppins Light;
    text-align: left !important;
    @media (min-width: 768px) {
      font-size: 16px !important;
    }
    @media (min-width: 1024px) {
      font-size: 18px !important;
    }
  `,
  Title1: styled.h1`
    color: #000 !important;
    font-size: 35px !important;
    max-width: 400px;
    text-align: center !important;
    font-family: Poppins Light;
    @media (min-width: 768px) {
      text-align: left !important;
    }
  `,
  DesSmall1: styled.p`
    color: #7f7e7e;
    font-weight: 600;
    font-size: 14px !important;
    padding-bottom: 5px !important;
    font-family: Poppins Light;
    text-align: center !important;
    @media (min-width: 768px) {
      text-align: left !important;
      font-size: 16px !important;
      max-width: 300px;
    }
    @media (min-width: 1024px) {
      font-size: 18px !important;
      max-width: 500px;
    }
  `,
  Des1: styled.span`
    font-family: Font Le Chant;
    color: #000;
    font-weight: 600;
    font-size: 35px !important;
    @media (min-width: 768px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 45px !important;
    }
    @media (min-width: 1150px) {
      font-size: 50px !important;
    }
    @media (min-width: 1440px) {
      font-size: 55px !important;
    }
  `,
  CardsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 2500px;
    gap: 3vw;
    padding: 0rem 1rem;
    justify-content: center;

    @media (min-width: 768px) {
      height: 1300px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1024px) {
      height: 850px;
      gap: 1vw;
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1150px) {
      height: 850px;
      gap: 3vw;
      grid-template-columns: repeat(3, 1fr);
    }
  `   
}
