
import React, {useEffect} from 'react';
import { useSnackbar } from 'notistack';

const Message = ({ message, type, isShow }) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(()=>{
    if(message == '' || message == undefined) return;
    if(type == '' || type == undefined) return;
    enqueueSnackbar(message, { variant: type });
  },[isShow])
  return (
    <div></div>
  );
}

export default Message;
