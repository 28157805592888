
import { createContext } from 'react';

const AppContext = createContext({
  topAppMenu: 0,
  topMenuChageHandler: () => {
  }
});
export {
  AppContext
};
