import {nftContractAddress, nft_count, IPFS_URL, CID_26} from '../../component/constant';
import {nftABI} from '../../abis/minter';

const pre_data = [[
    {
        name: 'Adoration #21',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection1/1.jpg'
    },
    {
        name: 'Adoration #22',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection1/2.jpg'
    },
    {
        name: 'Adoration #23',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection1/3.jpg'
    },
    {
        name: 'Adoration #24',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection1/4.jpg'
    },
    {
        name: 'Adoration #25',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection1/5.jpg'
    },
    {
        name: 'Adoration #26',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection1/6.jpg'
    }
],[
    {
        name: 'Adoration #94',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection2/1.jpg'
    },
    {
        name: 'Adoration #95',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection2/2.jpg'
    },
    {
        name: 'Adoration #96',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection2/3.jpg'
    },
    {
        name: 'Adoration #97',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection2/4.jpg'
    },
    {
        name: 'Adoration #98',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection2/5.jpg'
    },
    {
        name: 'Adoration #99',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection2/6.jpg'
    }
],[
    {
        name: 'Adoration #10001',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection3/1.jpg'
    },
    {
        name: 'Adoration #10002',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection3/2.jpg'
    },
    {
        name: 'Adoration #10003',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection3/3.jpg'
    },
    {
        name: 'Adoration #10004',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection3/4.jpg'
    },
    {
        name: 'Adoration #10005',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection3/5.jpg'
    },
    {
        name: 'Adoration #10006',
        description: 'Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        image: '/images/collection3/6.jpg'
    }
]]

const getTemplate = (No) => {
    
    let ret = pre_data[No]
    return ret;
}
const getData = async(web3, offset, No) => {
    
    let ret = [];
    const contract = new web3.eth.Contract(nftABI[No], nftContractAddress[No]);
    let _cnt = await contract.methods.totalMinted().call();

    if(_cnt > 0 && _cnt == 26) {
        for(let i = _cnt - nft_count + 1; i <= _cnt; i++) {
            let _json = await getMetadata(IPFS_URL + CID_26 + '/' + i + '.json');
            ret.push(_json);
        }
    }
    return ret;
}

const getMetadata = async(url) => {
    let ret = {};
    let response = await fetch(url);
    ret = await response.json();
    return ret;
}
export {
    getTemplate,
    getData
}