import { nftABI } from '../../abis/minter';
import { ADMIN_ADDRESS, nftContractAddress } from '../../component/constant';

const nftMint_26 = async (web3, ownerAddress) => {
    let ret = {msg:'',type:''};
    const contractMinter = new web3.eth.Contract(nftABI[0],  nftContractAddress[0]);
    try{
        let mintedAmount = await contractMinter.methods.totalMinted().call();
        let amount = 26;
        if(ownerAddress == ADMIN_ADDRESS) {
            if(mintedAmount < 26) {
                amount -= mintedAmount;
            } else {
                ret.msg = "Minting would exceed the private sale allocation";
                ret.type = 'error';
                return ret;
            }
            await contractMinter.methods.privatesaleBuy(amount)
                .send({ from: ownerAddress }, (_error, tx) => {
                    if(_error) {
                        ret.msg = "NFT mint failed. Please try again later.";
                        ret.type = 'error';
                    } else {
                        ret.msg = "You have minted " + amount + " NFTs successfully.";
                        ret.type = 'success';
                    }
                })  
        } else {
            ret.msg = "You are not allowed for private sale";
            ret.type = 'error';
        }
    }
    catch(e) {
    }

    return ret;
}

const getTotalMinted = async(web3) => {
    let ret = 0;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try{
        let publicsale = await contractMinter.methods.totalSupply().call();
        ret = publicsale;
    } catch(e) {
    }
    return ret;
}
const getSaleState = async (web3) => {
    let ret = 'locked';
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    // try{
    //     let saleIsActive = await contractMinter.methods.isSaleActive().call();
    //     ret = saleIsActive;        
    // } catch(e) {
    //     console.log(e)
    // }
    return ret;
}

const isPresaleUser = async(web3, ownerAddress) => {
    let ret = 'nouser';
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try{
        let presaleType = await contractMinter.methods._isPresaleUser(ownerAddress).call();
        ret = presaleType;
    } catch(e) {

    }
    return ret;
}

const getMintedAmount = async(web3, ownerAddress, saleState) => {
    let ret = 0;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        if((ownerAddress == ADMIN_ADDRESS) && saleState == 'private') {
            let privatedCount = await contractMinter.methods.privatedCount(ownerAddress).call();
            ret = privatedCount;
        } else {
            let mintedCount = await contractMinter.methods.mintedCount(ownerAddress).call();            
            ret = mintedCount;
        }
    } catch(e) {
    }
    return ret;
}

const nftMint = async (web3, ownerAddress, amount, price, presaleType, mintedAmount) => {
    let ret = {msg:'',type:''};
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try{
        let saleIsActive = await contractMinter.methods.isSaleActive().call();
         
        if(saleIsActive == 'locked') {
            ret.msg = "NFT Sale not started. Please wait.";
            ret.type = "error";
        } else if(saleIsActive == 'presale') {
            if(presaleType == "nouser") {
                ret.msg = "You have not been registered to whitelist. Please wait for public sale.";
                ret.type = 'error';
                return ret;
            }
            if(amount > 2) {
                ret.msg = "You can not mint exceeds maximum NFT. Please try again.";
                ret.type = 'error';
                return ret;
            }
            
            await contractMinter.methods.presaleBuy(amount)
                .send({ from: ownerAddress, value: (price*amount*1e18).toString() }, (_error, tx) => {
                    if(_error) {
                        ret.msg = "NFT mint failed. Please try again later.";
                        ret.type = 'error';
                    } else {
                        ret.msg = "You have minted " + amount + " NFTs successfully.";
                        ret.type = 'success';
                    }
                })
        } else if(saleIsActive == 'public') {
            if(amount > 3) {
                ret.msg = "You can not mint exceeds maximum NFT.";
                ret.type = 'error';
                return ret;
            }

            await contractMinter.methods.buy(amount)
                .send({ from: ownerAddress, value: (price*amount*1e18).toString() }, (_error, tx) => {
                    if(_error) {
                        ret.msg = "NFT mint failed. Please try again later.";
                        ret.type = 'error';
                    } else {
                        ret.msg = "You have minted " + amount + " NFTs successfully.";
                        ret.type = 'success';
                    }
                })
        } else if(saleIsActive == 'private') {
            if(ownerAddress == ADMIN_ADDRESS) {
                if(mintedAmount >= 50) {
                    ret.msg = "Minting would exceed the private sale allocation";
                    ret.type = 'error';
                    return ret;
                } else if(mintedAmount < 40) {
                    amount = 20;
                } else {
                    amount = 10;
                }
                await contractMinter.methods.privatesaleBuy(amount)
                    .send({ from: ownerAddress }, (_error, tx) => {
                        if(_error) {
                            ret.msg = "NFT mint failed. Please try again later.";
                            ret.type = 'error';
                        } else {
                            ret.msg = "You have minted " + amount + " NFTs successfully.";
                            ret.type = 'success';
                        }
                    })  
            } else {
                ret.msg = "You are not listed for private sale";
                ret.type = 'error';
            }
          
        }
    }
    catch(e) {
    }

    return ret;
}

const getRevealState = async (web3) => {
    let ret = 'locked';
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);

    try {
        let revealState = await contractMinter.methods.revealState().call();
         ret = revealState;
    } catch(e) {

    }
    return ret;
}
const setSale = async (web3, ownerAddress, state) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.toggleSaleStatus(state)
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
const setReveal = async (web3, ownerAddress, state) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.setReveal(state)
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
const setBaseURL = async (web3, ownerAddress, url) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.setDefaultBaseURI(url)
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
const setUnRevealURL = async (web3, ownerAddress, cidArr) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.setBaseURI(cidArr)
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
const setPublicRevealURL = async (web3, ownerAddress, cidArr) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.setPubBaseURI(cidArr)
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
const setAllRevealURL = async (web3, ownerAddress, cidArr) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.setRevealURI(cidArr)
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
const setWhitelist = async (web3, ownerAddress, arr) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.setWhiteList(arr)
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
const setRafflelist = async (web3, ownerAddress, arr) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.setRaffleList(arr)
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
const withDraw = async (web3, ownerAddress) => {
    let ret = false;
    const contractMinter = new web3.eth.Contract(nftABI[2], nftContractAddress[2]);
    try {
        await contractMinter.methods.withdraw()
            .send({ from: ownerAddress }, (_error, tx) => {
                if(!_error) {
                    ret = true;
                }
            })
    } catch(e) {
    }
    return ret;
}
export {
    nftMint_26,
    getTotalMinted,
    getMintedAmount,
    nftMint,
    getSaleState,
    isPresaleUser,
    getRevealState,
    setSale,
    setReveal,
    setBaseURL,
    setUnRevealURL,
    setPublicRevealURL,
    setAllRevealURL,
    setWhitelist,
    setRafflelist,
    withDraw
}
