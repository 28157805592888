import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {Button} from 'antd'

function CardTemplate({
  name,
  description,
  image,
  children
}) {

  return (
    <S.Card>
        <div style={{width: '100%'}}>
          <S.Img src={image} alt={name} />
        </div>          
        <S.Name>{name}</S.Name>
        <S.Tag>{description}</S.Tag>           
        <div>{children}</div>
    </S.Card>
  )
}
export default CardTemplate;
export const S = {
  Button: styled(Button)`
    background: white;
    color: black;
    border: 1px solid #dae0f9;
    margin-top: 10px;
    border-radius: 7px;
    text-align: center;
    padding: 2px 10px;

    &:hover,
    &:active,
    &:focus {
      background: #d2caca !important;
      color: black !important;
    }
    span {
      margin: 0 !important;
    }
  `,
  Card: styled.div`
    width: 100%;
    height: auto;
    max-width: 300px;
    border: 0;
    margin: 0 auto;

    .ant-spin.ant-spin-spinning {
      width: 100%;
      height: auto;
      max-height: 400px;
      margin: auto;
    }
  `,
  Content: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    min-height: 250px;

    @media (min-width: 768px) {
      min-height: 250px;
    }

    @media (min-width: 1024px) {
      min-height: 300px;
    }
  `,
  Img: styled.img`
    width: 300px;
    height: 300px;
    -webkit-user-drag: none;
  `,
  MainTag: styled.span`
    display: flex;
    justify-content: center;
    width: auto;
    height: 32px;
    padding: 10px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    font-weight: 800;
    font-size: 20px;
    line-height: 16px;
    color: white;
  `,
  Name: styled.span`
    display: flex;
    justify-content: left;
    align-items: left;
    width: auto;
    padding: 10px;
    font-weight: 900;
    font-size: 20px;
    line-height: 16px;
    color: black;
    font-family: Poppins Light;
  `,
  Tag: styled.span`
    display: flex;
    justify-content: left;
    align-items: left;
    text-align: left;
    width: auto;
    padding: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: black;    
    font-family: Poppins Light;
    @media (min-width: 1024px) {
      font-size: 14px;
    }
  `,
  Tags: styled.div`
    width: auto;
    max-width: 200px;
    min-width: 130px;
    background: white;
    display: block;
    flex-direction: row;
    text-align: center;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #817f86;
    span {
      margin-right: 10px;
    }
  `,
  LoadArea: styled.div`
    width: 1000%;
  `
}
