import React from 'react'
import styled from 'styled-components';

function Load() {
    return (<>
  {/* Start of learn section    
  ============================================= */}
  <section
    id="saas_two_load"
    className="saas_two_load_section relative-position"
  >
    <div className="container">
      <div className="about_content_center"> 
          <div className="s2-about_text_icon">
              <div className="s2-about_text saas2-headline text-center">
                  <S.Title>Road Map</S.Title>
              </div>
          </div>     
          <div className="row">
            <div
                className="col-lg-5 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <S.Content className="s2-about_text_icon">
                <div className="s2-about_text saas2-headline text-left">
                  <h3>Phase 1</h3>
                  <p>
                    Launch Amy's Adventures Club with the 26 Emotions NFT Collection<br></br>
                    26 Emotions is reserved for Investors and True Believers of the project 
                  </p>                  
                </div>
              </S.Content>
            </div>
            <div
                className="col-lg-2 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="img_center">
                <img src="images/phase1.png" alt='' />
              </div>
              <div className="img_center">
                <S.Img src="images/shape_bridge.png" alt='' />
              </div>
            </div>
          </div>
          <div className="row">
            <div
                className="col-lg-5 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
            </div>
            <div
                className="col-lg-2 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="img_center">
                <img src="images/phase2.png" alt='' />
              </div>
              <div className="img_center">
                <S.Img src="images/shape_bridge.png" alt='' />
              </div>
            </div>
            <div
                className="col-lg-5 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="s2-about_text_icon">
                <S.Content className="s2-about_text saas2-headline text-left">
                  <h3>Phase 2</h3>
                  <p>
                    Launch Amy's Friends NFT Collection. Amy's Friends Collection is reserved for Amy's Adventures Club Members. See "About Us" on how to become a member 
                  </p>                  
                </S.Content>
              </div>
            </div>
          </div>
          <div className="row">
            <div
                className="col-lg-5 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="s2-about_text_icon">
                <S.Content className="s2-about_text saas2-headline text-left">
                  <h3>Phase 3</h3>
                  <p>
                    Launch 88,888 Amy's Adventure NFT Collection. Buyers will receive Merchandise and other perks with NFT Purchase.<br></br>
                    These 88,888 NFTs will be available for the general public
                  </p>                  
                </S.Content>
              </div>
            </div>
            <div
                className="col-lg-2 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="img_center">
                <img src="images/phase1.png" alt='' />
              </div>
              <div className="img_center">
                <S.Img src="images/shape_bridge.png" alt='' />
              </div>
            </div>
          </div>
          <div className="row">
            <div
                className="col-lg-5 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
            </div>
            <div
                className="col-lg-2 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="img_center">
                <img src="images/phase2.png" alt='' />
              </div>
              <div className="img_center">
                {/* <S.Img src="images/shape_bridge.png" alt='' /> */}
              </div>
            </div>
            <div
                className="col-lg-5 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="s2-about_text_icon">
                <S.Content className="s2-about_text saas2-headline text-left">
                  <h3>Phase 4</h3>
                  <p>
                    Develop Amy's Adventure Cartoon
                  </p>                  
                </S.Content>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div
                className="col-lg-5 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="s2-about_text_icon">
                <S.Content className="s2-about_text saas2-headline text-right">
                  <h3>Phase 5</h3>
                  <p>
                    Cartoon Development 
                  </p>                  
                </S.Content>
              </div>
            </div>
            <div
                className="col-lg-2 col-md-12 wow fadeFromDown"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
            >
              <div className="img_center">
                <img src="images/phase1.png" alt='' />
              </div>
            </div>
          </div> */}
      </div>
      {/* /about first content */}
    </div>
  </section>
  {/* End of About  section    
  ============================================= */}
</>

    )
}

export default Load

const S = {
  Title: styled.div`
    font-family: Font Le Chant;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 35px !important;
    @media (min-width: 768px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 45px !important;
    }
    @media (min-width: 1150px) {
      font-size: 50px !important;
    }
    @media (min-width: 1440px) {
      font-size: 55px !important;
    }
  `,
  Content: styled.div`
    font-family: Poppins Light;
    h3 {
      font-size: 18px !important;
    }

    p {
      font-size: 12px !important;
    }

    @media (min-width: 768px) {
      h3 {
        font-size: 22px !important;
      }
  
      p {
        font-size: 16px !important;
      }
    }

    @media (min-width: 1280px) {
      h3 {
        font-size: 24px !important;
      }
  
      p {
        font-size: 18px !important;
      }
    }
  `,
  Img: styled.img`
    height: 126px;
    @media (min-width: 1024px) {
      height: 150px;
    }
  `
}
