import React from "react";
import styled from 'styled-components';

export const Slide = React.memo(function (StackedCarouselSlideProps) {
  const {
    data,
    dataIndex,
    isCenterSlide,
    swipeTo,
    slideIndex
  } = StackedCarouselSlideProps;

  const coverImage = data[dataIndex].image;
  const title = data[dataIndex].title;
  const text = data[dataIndex].text;
  let width = window.innerWidth ; 
  let height = 550;
  if(width>1024){
    height = 450;
  } else if (768<width&& width<1025) {
    height = 400;
  } else if (415<width&& width<769) {
    height = 300;
  } else if (width<415){
    height = 200;
  }
  return (
    <div
      className={`card-card ${isCenterSlide ? "center" : "on"}`}
      draggable={false}
      style={{height: height}}
    >
      <div
        className={`cover fill ${isCenterSlide ? "off" : "on"} 
        ${slideIndex > 0 ? "left" : "right"}`}
      >
        <div
          className={`card-overlay fill`}
          style={{
            backgroundColor:
              "#000000" + parseInt(70 - 60 / Math.abs(slideIndex), 10)
          }}
          onClick={() => {
            if (!isCenterSlide) swipeTo(slideIndex);
          }}
        />
      </div>
      <div
        className={`data fill  ${isCenterSlide ? "off" : "on"}  ${
          slideIndex > 0 ? "left" : "right"
        }`}
        style={{height: height}}
      >
        <div className="discription">
          <img
            style={{ width: "100%", height: height }}
            alt="j"
            className="cover-image"
            src={coverImage}
          />
          {isCenterSlide &&
            <div style={{textAlign: 'center', width: '200%', marginLeft: '-50%'}}>
              <S.Title>
                {title}
              </S.Title> 
              <S.Des>
                {text}
              </S.Des>               
            </div>            
          }                   
        </div>
      </div>
    </div>
  );
});

const S = {
  Title: styled.div`
    text-align: center;
    width: 100%;
    font-size: 23px;
    font-weight: bold;
    margin-top: 10px;
    font-family: Poppins Light;

    @media (min-width: 768px) {
      font-size: 23px;
    }
    @media (min-width: 1024px) {
      font-size: 25px;
    }
    @media (min-width: 1280px) {
      font-size: 30px;
    }
    @media (min-width: 1440px) {
      font-size: 35px;
    }
  `,
  Des: styled.div`
    text-align: center;
    width: 100%;
    font-size: 13px;
    font-family: Poppins Light;
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) {
      font-size: 18px;
    }
    @media (min-width: 1440px) {
      font-size: 20px;
    }
  `
}