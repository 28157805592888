import React, {useState, useEffect} from "react";
import Web3Modal from 'web3modal'
import Web3 from "web3";
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Main from "./component/Main";
import Mint from './component/Mint';
import Mint1 from './component/Mint1';
import Mint2 from './component/Mint2';
import Collections from './component/Collections';
import NaveBar from "./component/NaveBar";
import Footer from "./component/Footer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Message from './component/Message'
import { AppContext } from './component/contexts';
import {CHAIN_ID} from './component/constant';
import { getSaleState } from './services/mint';

const useStyles = makeStyles(() => ({
  primaryTextColor: {
    color: '#fff',
    fontSize: '16px'
  }
}));

function App() {
  const classes = useStyles();
  const [web3, setWeb3] = useState()
  const [provider, setProvider] = useState()
  const [connected, setConnected] = useState()
  const [address, setAddress] = useState('')
  const [chain_id, setChainId] = useState()
  const [message, setMessage] = useState('')
  const [msgType, setMsgType] = useState('')
  const [isMsg, setIsMsg] = useState(false);
  const [saleState, setSaleState] = useState('locked');
  const [loadingStatus, setLoadingStatus] = useState(false);

  const initWeb3 = (_provider) => {
    const _web3 = new Web3(_provider);
  
    _web3.eth.extend({
      methods: [
        {
          name: "chainId",
          call: "eth_chainId",
          outputFormatter: _web3.utils.hexToNumber
        }
      ]
    });
  
    return _web3;
  }

  const getProviderOptions = () => {
    const infuraId = 'b87b96a22d544319809fa30f8405f44d'//process.env.REACT_APP_INFURA_ID;
    const providerOptions = {
      // walletconnect: {
      //   package: WalletConnect,
      //   options: {
      //     infuraId
      //   }
      // },
      // walletlink: {
      //   package: WalletLink,
      //   options: {
      //     appName: "Web3Modal Example App",
      //     infuraId
      //   }
      // }
    };
    return providerOptions;
  };

  const web3Modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: true,
    providerOptions: getProviderOptions()
  });

  useEffect(()=>{
    if (web3Modal.cachedProvider) {
      onConnect();
    }
  },[])

  const onConnect = async () => {
    if(web3Modal.userOptions.length == 0) {      
      showMsg('Please install Metamask', 'error')
      window.open('https://metamask.io/download', '_blank');
      return;
    }
    try{      
      const _provider = await web3Modal.connect();
      

      await subscribeProvider(_provider);

      await _provider.enable();
      const _web3 = initWeb3(_provider);

      const accounts = await _web3.eth.getAccounts();

      const address = accounts[0];

      // const networkId = await _web3.eth.net.getId();

      const _chainId = await _web3.eth.chainId();
      if(_chainId != CHAIN_ID)
        changeNetwork();
      setWeb3(_web3)
      setAddress(address)
      setChainId(_chainId)
      setConnected(true)
      setProvider(_provider)

    } catch(e) {
      showMsg('rejected error', 'error')
    }
  };

  const changeNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(CHAIN_ID) }],
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const subscribeProvider = async (_provider) => {
    if (!_provider.on) {
      return;
    }
    _provider.on("close", () => resetApp());
    _provider.on("accountsChanged", async (accounts) => {
      if(accounts[0] == undefined) {
        resetApp()
      } else {
        setAddress(accounts[0])
      }      
    });
    _provider.on("chainChanged", async (chainId) => {      
      if(chainId != CHAIN_ID) {
        showMsg('Switch to Ethereum Mainnet', 'error')
      } else {
        setChainId(chainId)
      }
    });

    // _provider.on("networkChanged", async (chainId) => {      
    //   if(chainId != CHAIN_ID) {
    //     showMsg('Switch to Ethereum Mainnet', 'error')
    //   } else {
    //     setChainId(chainId)
    //   }
    // });

    _provider.on("connect", async () => {

    });

    _provider.on("disconnect", async () => {

    });
  };

  useEffect(()=>{
    if(!provider) return;
    subscribeProvider(provider)
  },[provider])

  const resetApp = async () => {
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await web3Modal.clearCachedProvider();

    setWeb3('')
    setAddress('')
    setChainId('')
    setConnected(false)
    setProvider('')
  };

  const showMsg = (msg, type) => {
    setMessage(msg);
    setMsgType(type);
    setIsMsg(!isMsg);
  }
  useEffect(()=>{
    const getSale = async()=>{
      if(address == '' || web3 == undefined) return;
      let state = await getSaleState(web3);
      setSaleState(state);
    }
    getSale();

  },[address])
  return (
    <div className="App">
      <BrowserRouter>
        <AppContext.Provider
          value={{
            web3,
            showMsg,
            onConnect,
            connected,
            address,
            saleState,
            setSaleState,
            loadingStatus,
            setLoadingStatus,
            chain_id
          }}>
          <SnackbarProvider
            classes={{
              variantSuccess: classes.primaryTextColor,
              variantError: classes.primaryTextColor,
              variantWarning: classes.primaryTextColor,
              variantInfo: classes.primaryTextColor
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            maxSnack={3}>
            <NaveBar/>
            <Switch>
              <Route path='/' exact component={Main} />
              <Route path='/mint' exact component={Mint} />
              <Route path='/mint1' exact component={Mint1} />
              <Route path='/mint2' exact component={Mint2} />
              <Route path='/collection' exact component={Collections} />
            </Switch>
            <Footer />
            <Message message={message} type={msgType} isShow={isMsg} />
          </SnackbarProvider>
        </AppContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
