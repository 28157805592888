import React, {useEffect} from "react";
import styled from 'styled-components';
import {
  StackedCarousel,
  ResponsiveContainer
} from "react-stacked-center-carousel";

import Fab from "@material-ui/core/Fab";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Slide } from "./Slide";
     
const data = [
  {
    image: "/images/slide/art1.png",
    title: "Adoration #1",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art2.png",
    title: "Adoration #2",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art3.png",
    title: "Adoration #3",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art4.png",
    title: "Adoration #4",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art5.png",
    title: "Adoration #5",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art6.png",
    title: "Adoration #6",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art7.png",
    title: "Adoration #7",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art8.png",
    title: "Adoration #8",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art9.png",
    title: "Adoration #9",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  },
  {
    image: "/images/slide/art10.png",
    title: "Adoration #10",
    text: "Lorem ipsum dolar sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
  }
];

const Card = () => {
  let slideNum = 5;
  let width = window.innerWidth ; 
  let customScales = [1, 0.85, 0.7, 0.55];
  let sWidth = 400;
  if(width>1024){
    sWidth = 400;
  } else if (768<width&& width<1025) {
    sWidth = 350;
  } else if (415<width&& width<769) {
    sWidth = 200;
  } else if (width<415){
    sWidth = 130;
    slideNum = 3;
    customScales = [1, 0.85, 0.7];
  }
    
  const ref = React.useRef(StackedCarousel);
  return (
    <div id="saas_two_card" className="card">
      <div className="card_text"><S.Title>26 Emotions</S.Title></div>      
      <div style={{ width: "95%", maxWidth: '1700px', position: "relative", margin: "0 auto"}}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(width, carouselRef) => {
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Slide}
                slideWidth={sWidth}
                carouselWidth={width}
                data={data}
                maxVisibleSlide={slideNum}
                disableSwipe
                customScales={customScales}
                transitionTime={450}
                height={sWidth <= 200 ? sWidth*2.5 : sWidth*1.5}
              ></StackedCarousel>
            );
          }}
        />
        <Fab
          className="card-button left"
          size="small"
          onClick={() => ref.current?.goBack()}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: 30 }} />
        </Fab>
        <Fab
          className="card-button right"
          size="small"
          onClick={() => ref.current?.goNext()}
        >
          <KeyboardArrowRightIcon style={{ fontSize: 30 }} />
        </Fab>
      </div>
    </div>
  );
};

export default Card;

const S = {
  Title: styled.div`
    font-family: Font Le Chant;
    color: #000;
    font-weight: 600;
    margin-top: 50px;
    font-size: 35px !important;
    @media (min-width: 768px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 45px !important;
    }
    @media (min-width: 1150px) {
      font-size: 50px !important;
    }
    @media (min-width: 1440px) {
      font-size: 55px !important;
    }
  `
}
