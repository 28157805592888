import React, {useContext} from "react";
import styled from 'styled-components'
import { Button } from 'antd'
import {Link} from 'react-router-dom';
import { AppContext } from './contexts';
import {ADMIN_ADDRESS} from './constant';

function NaveBar() {

  const {onConnect, address} = useContext(AppContext)

  const connect = async (e) => {
    e.preventDefault()
    onConnect()
  }
  return (
    <>
      <header
        id="header_main"
        className="saas_two_main_header"
        style={{ position: "fixed" }}
      >
        <div className="container">
          <div className="s_main_menu">
            <div className="row" style={{justifyContent: 'space-between'}}>
              <S.Logo src="images/logo_img.png" alt='' />
              <S.MenuDiv className="col-md-10" style={{paddingTop: 4, justifyContent: 'flex-end', display: 'flex'}}>
                <div className="main_menu_list clearfix float-right" style={{justifyContent: 'space-between', display: 'flex'}}>
                  <nav className="s2-main-navigation  clearfix ul-li">
                    <ul
                      id="main-nav"
                      className="navbar-nav text-capitalize clearfix"
                    >
                      {/* <S.Link>
                        <a href="#saas_two_banner"><S.MenuFont>Home</S.MenuFont></a>
                      </S.Link> */}
                      {/* <S.Link>
                        <a href="/mint"><S.MenuFont>Mint</S.MenuFont></a>
                      </S.Link> */}
                      <S.Link>
                        <Link to="/collection"><S.MenuFont>Collections</S.MenuFont></Link>
                      </S.Link>
                      <S.Link>
                        <Link to="/#saas_two_load"><S.MenuFont>Road Map</S.MenuFont></Link>
                      </S.Link>
                      <S.Link>
                        <Link to="/#saas_two_learn"><S.MenuFont>About Us</S.MenuFont></Link>
                      </S.Link>                      
                      {/* <S.Link>
                        <a href="#saas_two_contact"><S.MenuFont>Contact Us</S.MenuFont></a>
                      </S.Link> */}
                      {/* {(address == ADMIN_ADDRESS || address == WITHDRAW_ADDRESS) ?
                        <S.Link>
                          <a href="#saas_two_setting"><S.MenuFont>Settings</S.MenuFont></a>
                        </S.Link>
                        :
                        <li style={{width: '0px'}}>
                          <a href="#saas_two_setting"></a>
                        </li>
                      } */}
                    </ul>                    
                  </nav>
                  <S.Container>
                    {address?
                      <S.ButtonConnected>
                        <S.Image src={'images/metamask.svg'} alt='Wallet' />
                        {address.substr(0, 4) + '...' + address.substr(address.length - 4, address.length)}
                      </S.ButtonConnected>                     
                    :
                      <S.ButtonConnectWallet onClick={e=>connect(e)}>
                        <S.Image src={'images/metamask.svg'} alt='Wallet' />
                        Connect Wallet
                      </S.ButtonConnectWallet>                      
                    }
                  </S.Container>
                </div>
              </S.MenuDiv>
            </div>
          </div>
          {/* //desktop menu */}
          <div className="s2-mobile_menu relative-position">
            <S.MenuButton className="s2-mobile_menu_button s2-open_mobile_menu">
              <i className="fas fa-bars" />
            </S.MenuButton>
            <div className="s2-mobile_menu_wrap">
              <div className="mobile_menu_overlay s2-open_mobile_menu" />
              <div className="s2-mobile_menu_content">
                <div className="s2-mobile_menu_close s2-open_mobile_menu">
                  <i className="far fa-times-circle" />
                </div>
                <div className="m-brand-logo text-center">
                  <a href="">
                    <S.Logo src="images/logo_img.png" alt='' />
                  </a>
                </div>
                <nav className="s2-mobile-main-navigation  clearfix ul-li">
                  <ul
                    id="m-main-nav"
                    className="navbar-nav text-capitalize clearfix"
                  >
                      {/* <S.Link>
                        <a href="#saas_two_banner">Home</a>
                      </S.Link> */}
                      {/* <S.Link>
                        <a href="/mint">Mint</a>
                      </S.Link> */}
                      <S.Link>
                        <Link to="/collection">Collections</Link>
                      </S.Link>
                      <S.Link>
                        <Link to="/#saas_two_load">Road Map</Link>
                      </S.Link>
                      <S.Link>
                        <Link to="/#saas_two_learn">About Us</Link>
                      </S.Link>
                      {/* <S.Link>
                        <a href="#saas_two_contact">Contact Us</a>
                      </S.Link> */}
                      {/* {(address == ADMIN_ADDRESS || address == WITHDRAW_ADDRESS) ?
                        <S.Link>
                          <a href="#saas_two_setting">Settings</a>
                        </S.Link>
                        :
                        <li style={{width: '0px'}}>
                          <a href="#saas_two_setting"></a>
                        </li>
                      } */}
                  </ul>                  
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default NaveBar;

const S = {
  Link: styled.li`
    margin-right: 25px !important;
    @media (min-width: 1024px) {
      margin-right: 15px !important;
    }
    @media (min-width: 1150px) {
      margin-right: 25px !important;
    }
  `,
  MenuFont: styled.span`
    font-family: 'Poppins Light';
    font-weight: 800;
    font-size: 18px;
  `,
  MenuButton: styled.div`
    top: -52px;
    @media (min-width: 768px) {
      top: -50px;
    }
  `,
  LogoDiv: styled.div`
    width: 130px;
    padding: 5px;
    @media (min-width: 768px) {
      width: 170px;
      padding-top: 5px !important;
    } 
    @media (min-width: 1024px) {
      padding-top: 5px !important;
    }
  `,
  MenuDiv: styled.div`
    width: 200px;
    @media (min-width: 414px) {
      width: 230px;
    }  
    @media (min-width: 768px) {
      padding-right: 5rem;
      width: 250px;
      @media (min-width: 768px) {
        padding-right: 0px;
      }
    }    
  `,
  Logo: styled.img`
    width: 70px;
  `,
  Image: styled.img`
    margin-left: 0 !important;
    margin-right: 10px;
  `,
  Container: styled.div`
    display: flex;
    margin-top: 30px;
    @media (min-width: 768px) {
      margin-top: 40px;
    }
  `,
  ButtonConnectWallet: styled(Button)`
    display: block;
    height: 48px;
    border-radius: 30px;
    border: 2px solid red;
    background: #c40e0e;
    color: #fff;
    padding: 0 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;    
    margin-top: -20px;
    margin-right: 50px;
    font-family: "Poppins";
    font-family: 'Poppins Light';

    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    img {
      width: 24px;
      height: auto;
      margin-left: 14px;
    }

    &:hover,
    &:active {
      background: #2b2530;
      box-shadow: none;
    }

    &::after {
      display: none;
    }
    @media (min-width: 768px) {
      font-size: 20px;    
      margin-top: -30px;
      margin-right: 70px;
    }
    @media (min-width: 1024px) { 
      margin-top: -35px;
      margin-right: 10px;
    }
  `,
  ButtonConnected: styled(Button)`
    display: block;
    height: 48px;
    border-radius: 30px;
    border: 2px solid red;
    background: #fafafa;
    color: red;
    padding: 0 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;    
    margin-top: -20px;
    margin-right: 50px;
    font-family: 'Poppins Light';

    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    @media (min-width: 768px) {
      margin-top: -30px;
      margin-right: 70px;
    }

    @media (min-width: 1024px) {
      margin-top: -30px;
      margin-right: 10px;
    }

    img {
      width: 24px;
      height: auto;
      margin-left: 14px;
    }

    &:hover,
    &:active {
      background: #2b2530;
      box-shadow: none;
    }

    &::after {
      display: none;
    }
  `
}
